'use client';

import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import { initI18n } from '../../../common/i18n/client';
import { Configuration } from '../../../configuration';
import { ConfigContextProvider } from '../../../contexts/ConfigContext';

const LayoutContent = dynamic(() => import('./LayoutContent'), { ssr: false });

initI18n();

export function Layout({ config, ...props }: { children: ReactNode; config: Configuration }) {
  return (
    <ConfigContextProvider config={config}>
      <LayoutContent {...props} />
    </ConfigContextProvider>
  );
}
